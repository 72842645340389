import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    withRouter,
    Redirect,
    Link as routerLink,
    useRouteMatch
} from "react-router-dom";
import {
    Box,
    Text,
    Image,
    HStack,
    Center,
} from "@chakra-ui/react";

import { blogThumbnail } from '../Assets';

export default function SidePanel() {
  return (
    <>
          <Box border={"1px solid lightgrey"} px={5} py={10} mb={50}>
              <Center fontSize={"2xl"} mb={5}>
                  Latest News
              </Center>

              <HStack justifyContent={"space-around"} mb={5}>
                  <Image src={blogThumbnail} mr={5} />

                  <Box>
                      <Text fontWeight={"bold"}>
                          Building Diverse Design Was Teams Innovation.
                      </Text>
                      <Text>
                          05 Jan 2019
                      </Text>
                  </Box>
              </HStack>

              <HStack justifyContent={"space-around"} mb={5}>
                  <Image src={blogThumbnail} mr={5} />

                  <Box>
                      <Text fontWeight={"bold"}>
                          Building Diverse Design Was Teams Innovation.
                      </Text>
                      <Text>
                          05 Jan 2019
                      </Text>
                  </Box>
              </HStack>

              <HStack justifyContent={"space-around"} mb={5}>
                  <Image src={blogThumbnail} mr={5} />

                  <Box>
                      <Text fontWeight={"bold"}>
                          Building Diverse Design Was Teams Innovation.
                      </Text>
                      <Text>
                          05 Jan 2019
                      </Text>
                  </Box>
              </HStack>
          </Box>


          <Box border={"1px solid lightgrey"} px={5} py={10} mb={50}>

              <Center fontSize={"2xl"} mb={5}>
                  Category
              </Center>

              <HStack justifyContent={"space-between"} mb={5}>
                  <Text>
                      Web Development
                  </Text>

                  <Text fontWeight={"bold"}>
                      (7)
                  </Text>
              </HStack>

              <HStack justifyContent={"space-between"} mb={5}>
                  <Text>
                      Digital Marketing
                  </Text>

                  <Text fontWeight={"bold"}>
                      (11)
                  </Text>
              </HStack>

              <HStack justifyContent={"space-between"} mb={5}>
                  <Text>
                      App Developemnt
                  </Text>

                  <Text fontWeight={"bold"}>
                      (7)
                  </Text>
              </HStack>
          </Box>
    </>
  )
}
