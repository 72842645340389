import Logo from '../libs/assets/brand/logo.png'
import LogoAlt from '../libs/assets/brand/logo-colored.png'

const Brand = {
    logo: Logo,
    logoAlt: LogoAlt,
    alt: "Sellsar Logo",
    color: "#FE805A"
}

export {
    Brand
}