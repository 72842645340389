import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link as routerLink } from 'react-router-dom';
import {
    Box,
    Text,
    Icon,
    Image,
    Input,
    Stack,
    Button,
    Container,
    Center,
    Spinner,
    useToast,
    VStack,
    Link
} from "@chakra-ui/react";
import { 
    FaArrowAltCircleRight 
} from "react-icons/fa";
import {
    bg,
    bgIllustrationTwo,
    bgIconOne,
    bgIconTwo,
    bgIconThree,
    bgBanner,
    bgWave,
    bgCTA,
    bgRectangleBlob,
    bgMarketYourBusiness,
    bgManageEverything,
    bgSellEverywhere,
} from './Assets';
import {
    mapStateToProps,
    mapDispatchToProps,
} from './Helpers';

import { ValidateEmail } from '../../Helpers';

import Header from '../../components/Header/Index';
import Footer from '../../components/Footer/Index';

import PageActivityIndicator from '../../components/Indicator/PageActivityIndicator';
import { Redirect } from 'react-router-dom';


function Index(props) {
    const toast = useToast()

    const [isLoading, setIsLoading] = React.useState(true)
    const [redirect, setRedirect] = React.useState(null)

    const [UserEmail, setUserEmail] = React.useState('')

    useEffect(() => {
        //
        setIsLoading(false)

    }, [])

    if (redirect) {
        return <Redirect to={redirect} />
    }
    
    return (
        <>
            {
                isLoading ? (
                    <PageActivityIndicator />
                ) : (
                    <>
                            <Box pos={"relative"} minH={['640px', '900px', '900px']} style={{ height: '100vh', background: `url(${bg})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                            <Header />

                            <Container maxW="8xl">
                                <Stack direction={"column"} alignItems={"center"} mt={["25px", "50px", "50px", "50px"]}>
                                    <Text color="brand" fontSize={["3xl", "4xl", "5xl", "6xl"]} fontWeight="extrabold">
                                        Welcome to Sellsar
                                    </Text>

                                    <Text color="white" fontSize={["xs", "sm", "lg", "xl"]} fontWeight="bold" mt={0}>
                                        {/* Your number one online marketplace */}
                                        The business tool every seller needs
                                    </Text>

                                    <Stack width={"100%"} direction={["column", "column", "row", "row",]} justifyContent={"center"} alignItems={"center"} py={5}>
                                        <Input
                                            size="lg"
                                            boxShadow={"md"}
                                            variant={"filled"}
                                            borderRadius={"md"}
                                            width={['90%', '90%', '350px', '350px']}
                                            colorScheme={"orange"}
                                            fontSize={["sm", "md"]}
                                            color={"white"}
                                            
                                            bg={"transparent"}
                                            border={"1px solid"}
                                            borderColor={"brand"}
                                            
                                            focusBorderColor="brand"
                                            // errorBorderColor="brand"
                                            placeholder="Enter your email address..."
                                            value={UserEmail}
                                            onChange={e => setUserEmail(e.target.value)}
                                        />

                                        <Button onClick={async () => {
                                            if (ValidateEmail(UserEmail)) {
                                                return setRedirect(`/get-started?email=${UserEmail}`)
                                            } else {
                                                return toast({
                                                    title: "Invalid EMAIL",
                                                    description: "The EMAIL entered is not a valid EMAIL",
                                                    status: "error",
                                                    duration: 5000,
                                                    isClosable: true,
                                                    position: "top-right",
                                                })
                                            }
                                        }}
                                            size="lg"
                                            variant="solid"
                                            width={['90%', 'unset%', 'unset', 'unset']}
                                            fontSize={["md", "lg"]}
                                            focusBorderColor="red"
                                            borderRadius="md"
                                            colorScheme="brand"
                                            bg="brand"
                                        >
                                            {/* Get Started */}
                                            Try Demo
                                        </Button>
                                    </Stack>

                                    <Text color="white" fontSize={["xs", "sm"]} textAlign={"center"} maxW={500}>
                                        Signup to enjoy our free 90days trial, no down payment, no credit card required,
                                        just signup and start enjoying our services. Grow your business, while we make sure everything works.
                                    </Text>
                                </Stack>

                                <Center>
                                    <Image src={bgIllustrationTwo} objectFit="contain" pos={"absolute"} bottom={0} />
                                </Center>
                            </Container>
                        </Box>

                        <Container maxW="8xl" pos={"relative"} top={"-20px"}>
                            <Box width={["100%", "100%", "100%", "90%", "80%"]} margin={"auto"} borderRadius={"3xl"} padding={"50px 50px"} bg={"white"} border={"1px solid lightgrey"} boxShadow={"2xl"}>
                                <Stack direction={["column", "column", "column", "row"]} justifyContent={["space-between"]} alignItems={"center"}>
                                    <Box padding={"30px"} minHeight={"250px"} minWidth={"250px"} maxWidth={"300px"} boxShadow={"2xl"} borderRadius={"3xl"} bg={"white"}>
                                        <VStack align={"start"}>
                                            <Image height={50} margin={"0 0 20px 0"} src={bgIconOne} objectFit="contain" />
                                            <Text fontSize={16} color={"black"} fontWeight={'bold'}>Easy to use seller tools</Text>
                                            <Text>Manage your inventory, track orders and receive payments securely and ship with ease</Text>
                                        </VStack>
                                    </Box>

                                    <Box padding={"30px"} minHeight={"250px"} minWidth={"250px"} maxWidth={"300px"} boxShadow={"2xl"} borderRadius={"3xl"} bg={"white"}>
                                        <VStack align={"start"}>
                                            <Image height={50} margin={"0 0 20px 0"} src={bgIconTwo} objectFit="contain" />
                                            <Text fontSize={16} color={"black"} fontWeight={'bold'}>Customizable templates</Text>
                                            <Text>Easily customizable template designs allowing you launch your store quickly and easily.</Text>
                                        </VStack>
                                    </Box>

                                    <Box padding={"30px"} minHeight={"250px"} minWidth={"250px"} maxWidth={"300px"} boxShadow={"2xl"} borderRadius={"3xl"} bg={"white"}>
                                        <VStack align={"start"}>
                                            <Image height={50} margin={"0 0 20px 0"} src={bgIconThree} objectFit="contain" />
                                            <Text fontSize={16} color={"black"} fontWeight={'bold'}>Grow your brand</Text>
                                            <Text>Custom domain, Analytics and Marketing tools to reach your customers and distinguish your brand.</Text>
                                        </VStack>
                                    </Box>
                                </Stack>
                            </Box>
                        </Container>


                        <Container maxW="8xl" mt={50}>
                            <Box width="100%" paddingX={["10px", "10px", "10px", "10px", "50px"]}>
                                <Stack direction={["column", "column", "column", "row"]} justifyContent={"space-between"} alignItems={"center"}>
                                    <Box padding={"20px 20px"} width={"100%"}>
                                        <Image width={"100%"} src={bgSellEverywhere} objectFit="contain" />
                                    </Box>

                                    <Box padding={"20px 20px"} width={"100%"}>
                                        <VStack align={"start"}>
                                            <Text fontSize={18} color={"black"} fontWeight={'bold'}>
                                                Sell everywhere
                                            </Text>
                                            <Text fontSize={16}>
                                                Use one platform to sell products to anyone, anywhere—in person with Point of Sale and online through your website, social media, and online marketplaces.
                                            </Text>
                                        </VStack>
                                    </Box>

                                </Stack>
                            </Box>

                            <Box width="100%" mt="20" paddingX={["10px", "10px", "10px", "10px", "50px"]}>
                                <Stack direction={["column", "column", "column", "row"]} justifyContent={"space-between"} alignItems={"center"}>
                                    <Box padding={"20px 20px"} width={"100%"} display={["none", "none", "none", "block"]}>
                                        <VStack align={"start"}>
                                            <Text fontSize={18} color={"black"} fontWeight={'bold'}>
                                                Market your business
                                            </Text>
                                            <Text fontSize={16}>
                                                Take the guesswork out of marketing with built-in tools that help you create, execute, and analyze digital marketing campaigns.
                                            </Text>
                                        </VStack>
                                    </Box>

                                    <Box padding={"20px 20px"} width={"100%"}>
                                        <Image width={"100%"} src={bgManageEverything} objectFit="contain" />
                                    </Box>

                                    <Box padding={"20px 20px"} width={"100%"} display={["block", "block", "block", "none"]}>
                                        <VStack align={"start"}>
                                            <Text fontSize={18} color={"black"} fontWeight={'bold'}>
                                                Market your business
                                            </Text>
                                            <Text fontSize={16}>
                                                Take the guesswork out of marketing with built-in tools that help you create, execute, and analyze digital marketing campaigns.
                                            </Text>
                                        </VStack>
                                    </Box>
                                </Stack>
                            </Box>

                            <Box width="100%" mt="20" paddingX={["10px", "10px", "10px", "10px", "50px"]}>
                                <Stack direction={["column", "column", "column", "row"]} justifyContent={"space-between"} alignItems={"center"}>
                                    <Box padding={"20px 20px"} width={"100%"}>
                                        <Image width={"100%"} src={bgMarketYourBusiness} objectFit="contain" />
                                    </Box>

                                    <Box padding={"20px 20px"} width={"100%"}>
                                        <VStack align={"start"}>
                                            <Text fontSize={16} color={"black"} fontWeight={'bold'}>
                                                Manage everything
                                            </Text>
                                            <Text fontSize={16}>
                                                Gain the insights you need to grow—use a single dashboard to manage orders, shipping, and payments anywhere you go.
                                            </Text>
                                        </VStack>
                                    </Box>
                                </Stack>
                            </Box>
                        </Container>


                        <Container maxW="8xl" mt={100}>
                            <Box height={"600px"} background={`url(${bgBanner})`} backgroundSize={"cover"} borderRadius={"3xl"}>
                                <Center marginLeft={"auto"} width={["100%", "100%", "100%", "50%"]} height={"100%"} justifyContent={["center", "center", "center", "center"]}>
                                    <Box padding={"20px"} bg={"#1d1e6d"}>
                                        <Text color={"white"} fontSize={"md"}>
                                            An Online Store that Actually Sells...
                                        </Text>

                                        <Text color={"white"} fontSize={"5xl"}>
                                            <Center>
                                                Get Started
                                                <Icon as={FaArrowAltCircleRight} ml={"5"} />
                                            </Center>
                                        </Text>
                                    </Box>

                                </Center>
                            </Box>
                        </Container>


                        <Box pos={"relative"}
                            mt={100}
                            marginTop={["50px", "120px", "120px", "120px"]}
                        >
                            <Center>
                                <Image src={bgRectangleBlob}
                                    objectFit={"contain"}
                                    display={["none", "none", "none", "block"]}
                                    maxWidth={"90%"}
                                />

                                <VStack pos={["relative", "relative", "relative", "absolute"]} top={[0, 0, 0, '50px']} left={0} right={0}>
                                    <Text color={['grey', 'grey', 'grey', 'white']} fontSize={20}>
                                        {/* Ready to launch your online store? */}
                                        Can't wait to see how it looks?
                                    </Text>

                                    <Box as={routerLink} to={"/get-started"} position={'relative'}>
                                        <Text pos={"absolute"}
                                            top={10}
                                            left={0}
                                            right={0}
                                            color={'white'}
                                            textAlign={'center'}
                                        >
                                            <Center>
                                                Try Demo
                                                <Icon as={FaArrowAltCircleRight} ml={"1"} />
                                            </Center>
                                        </Text>

                                        <Image src={bgCTA}
                                            marginLeft={'auto'}
                                            marginRight={'auto'}
                                            objectFit={"contain"}
                                        />
                                    </Box>
                                </VStack>
                            </Center>

                            <Image src={bgWave} objectFit="contain" />
                        </Box>

                        <Footer style={{ margin: 0 }} />
                    </>
                )
            }
        </>
    )
}


export default connect(mapStateToProps, mapDispatchToProps)(Index)
