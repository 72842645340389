import { store } from './libs/redux/Store';
import { logout } from './libs/redux/actions/AuthActions';

export const PostMan = async (
    uriPath,
    method,
    payload,
    stringified = true,
    xhost = null,
    headers = {}
) => {
    let responseObject
    const {
        auth
    } = store.getState()

    const getHostUri = (xhost) => {
        if (xhost) { return xhost }
        else {
            return process.env.REACT_APP_API_URL
        }
    }

    const _attemptLogout = async () => {
        await store.dispatch(logout())
    }

    const setHeaders = () => {
        if (headers // 👈 null and undefined check
            && Object.keys(headers).length === 0
            && Object.getPrototypeOf(headers) === Object.prototype
        ) {
            // if (auth.accessToken) { headers['Authorization'] = `jwt ${auth.accessToken}` }
            if (stringified) { headers['Content-Type'] = 'application/json' }
            if (stringified) { headers['Accept'] = 'application/json' }
        }
        return headers
    }

    let url = getHostUri(xhost) + uriPath
    console.log("url: ", url)
    let requestBody = {
        method: method,
        headers: new Headers(setHeaders()),
        // body: stringified ? JSON.stringify(payload) : payload
    }
    if (payload) {
        requestBody['body'] = stringified ? JSON.stringify(payload) : payload
    }

    await fetch(url, requestBody)
        .then(async response => {
            // console.log("response: ", response)
            const contentType = response.headers.get("content-type");
            let errorData

            if (response.ok) {
                if (response.status === 200 || response.status === 201) {

                    if (contentType.indexOf("application/json") !== -1) {
                        return {
                            statusCode: response.status,
                            data: await response.json(),
                        }
                    } else if (contentType.indexOf("application/pdf") !== -1 || contentType.indexOf("image/") !== -1) {
                        return {
                            statusCode: response.status,
                            data: await response.blob(),
                        }
                    } else {
                        return {
                            statusCode: response.status,
                            data: await response.text(),
                        }
                    }
                } else if (response.status === 204) {
                    return {
                        statusCode: response.status,
                        data: null,
                    }
                }
            } else {
                if (response.status === 400 || 401) {
                    return {
                        statusCode: response.status,
                        data: await response.json(),
                    }
                }

                if (response.status === 404) {
                    return {
                        statusCode: response.status,
                        data: await response.text(),
                    }
                }


                errorData = await response.json()
                throw new Error(errorData.message)
            }
        })
        .then(responseData => {
            if (responseData.statusCode === 400) {
                responseObject = {
                    data: responseData.data,
                    status: 'bad_request',
                }
            } else if (responseData.statusCode === 401) {
                responseObject = {
                    data: responseData.data,
                    status: 'unauthorized',
                }
                // Attempt logout
                console.log("Attempting Logout")
                return _attemptLogout()

            } else if (responseData.statusCode === 404) {
                responseObject = {
                    data: {
                        message: "Not Found",
                        content: responseData.data
                    },
                    status: 'error',
                }
            } else {
                responseObject = {
                    data: responseData.data,
                    status: 'success',
                }
            }
        })
        .catch(errorData => {
            // console.log("errorData: ", errorData.message)
            responseObject = {
                data: {
                    message: errorData.message,
                },
                status: 'error',
            }
        })
    return responseObject
}


export function ValidateEmail(email) {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
        return (true)
    }
    return (false)
}


export function NumberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


export function NormalizePhoneNumber(value, previousValue, toDisplay) {
    // console.log(value, previousValue, toDisplay)

    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;

    // console.log('currentValue: ', currentValue)

    if (toDisplay) {
        if (!previousValue || value.length > previousValue.length) {
            if (cvLength < 4) return currentValue;
            if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
            let normalizedOutput = `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
            return [currentValue, normalizedOutput]
        }
    }
    return [currentValue, null]
};


export function FormValidator(field) {
    let status
    let validationMessage

    // Input Field ?
    if (field.element === 'input') {
        switch (field.props.type) {
            case 'email':
                if (!field.value || field.value == ' ') {
                    status = 'error'
                    validationMessage = 'Email is required'
                } else if (!ValidateEmail(field.value)) {
                    status = 'error'
                    validationMessage = 'Email is invalid'
                }
                break;
            case 'tel':
                if (!field.value || field.value == ' ') {
                    status = 'error'
                    validationMessage = 'Phone Number is required'
                }
                else {
                    let normalizePhoneNumber = NormalizePhoneNumber(field.value)[0]
                    if (normalizePhoneNumber.length != 10) {
                        status = 'error'
                        validationMessage = 'Phone Number is invalid'
                    }
                }

                break;
            case 'password':
                if (!field.value || field.value == ' ') {
                    status = 'error'
                    validationMessage = 'Password is required'
                }
                else if (field.props.name != 'password' && field.value.length < 6) {
                    status = 'error'
                    validationMessage = 'Password is too short'
                }
                if (!validationMessage) {
                    status = 'success'
                    validationMessage = 'Password confirmed'
                }
                break;

            default:
                if (!field.value || field.value == ' ') {
                    status = 'error'
                    validationMessage = field.labelText + ' is required'
                }
                break;
        }
    }

    // Select Field ?
    if (field.element === 'select') {
        if (!field.value || field.value == ' ') {
            status = 'error'
            validationMessage = field.labelText + ' is required'
        }
    }

    return { validationMessage, status }
}