import React from 'react';
import { 
    Box, 
    Center,
    Image,
    Stack
} from '@chakra-ui/react';

import ActivityIndicator from './ActivityIndicator';
import { Brand } from './Assets';


const PageActivityIndicator = ({ children }) => {
    return (
        <>
            <Center height={"100vh"} bg={"secondary"}>
                <Stack>
                    <Image src={Brand.logo} alt={Brand.alt} h={55} mb={5} />

                    <Box style={{ background: 'transparent' }} zIndex={99}>
                        {children ? children : (
                            <ActivityIndicator />
                        )}
                    </Box>
                </Stack>
            </Center>
        </>
    )
}

export default PageActivityIndicator
