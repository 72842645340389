import React from 'react';
import {
    Link as routerLink,
} from 'react-router-dom'
import {
    Box,
    Button,
    Divider,
    HStack,
    Image,
    Text,
    Link
} from '@chakra-ui/react';

import { AiOutlineComment, AiOutlineEye } from 'react-icons/ai';
import { FaRegCalendarAlt } from 'react-icons/fa';


import { blogPost1, blogPost2, blogPost3, } from '../Assets';

export default function BlogListItem() {
    return (
        <>
            <Box pb={100}>
                <Image src={blogPost2} />

                <Box>
                    <HStack spacing={5} py={2}>
                        <HStack>
                            <FaRegCalendarAlt size={20} />

                            <Text>
                                29 April 2019
                            </Text>
                        </HStack>

                        <Divider height={8} size={"4xl"} orientation={"vertical"} />

                        <HStack>
                            <AiOutlineComment size={20} />

                            <Text>
                                28 Comments
                            </Text>
                        </HStack>

                        <Divider height={8} size={"4xl"} orientation={"vertical"} />

                        <HStack>
                            <AiOutlineEye size={20} />

                            <Text>
                                350 Views
                            </Text>
                        </HStack>
                    </HStack>

                    <Box width={"90%"}>
                        <Link as={routerLink} to={"/blog/post-1"}>
                            <Text fontSize={"3xl"} mb={3}>
                                Introducing Smart Calenders
                            </Text>
                        </Link>

                        <Text>
                            So I said knackered do one ummm I'm telling up the kyver arse over tit smashing lurgy lost the plot, owt to do with me plastered easy peasy ...
                        </Text>

                        <Button as={routerLink} to={"/blog/post-1"} mt={5} size={"lg"}>
                            Read More
                        </Button>
                    </Box>
                </Box>
            </Box>

            <Box pb={100}>
                <Image src={blogPost1} width={"100%"} />

                <Box>
                    <HStack spacing={5} py={2}>
                        <HStack>
                            <FaRegCalendarAlt size={20} />

                            <Text>
                                29 April 2019
                            </Text>
                        </HStack>

                        <Divider height={8} size={"4xl"} orientation={"vertical"} />

                        <HStack>
                            <AiOutlineComment size={20} />

                            <Text>
                                28 Comments
                            </Text>
                        </HStack>

                        <Divider height={8} size={"4xl"} orientation={"vertical"} />

                        <HStack>
                            <AiOutlineEye size={20} />

                            <Text>
                                350 Views
                            </Text>
                        </HStack>
                    </HStack>

                    <Box width={"90%"}>
                        <Link as={routerLink} to={"/blog/post-2"}>
                            <Text fontSize={"3xl"} mb={3}>
                                Best Saas Software Tips You Will Read This Year
                            </Text>
                        </Link>

                        <Text>
                            So I said knackered do one ummm I'm telling up the kyver arse over tit smashing lurgy lost the plot, owt to do with me plastered easy peasy ...
                        </Text>

                        <Button as={routerLink} to={"/blog/post-2"} mt={5} size={"lg"}>
                            Read More
                        </Button>
                    </Box>
                </Box>
            </Box>

            <Box pb={100}>
                <Image src={blogPost3} />

                <Box>
                    <HStack spacing={5} py={2}>
                        <HStack>
                            <FaRegCalendarAlt size={20} />

                            <Text>
                                29 April 2019
                            </Text>
                        </HStack>

                        <Divider height={8} size={"4xl"} orientation={"vertical"} />

                        <HStack>
                            <AiOutlineComment size={20} />

                            <Text>
                                28 Comments
                            </Text>
                        </HStack>

                        <Divider height={8} size={"4xl"} orientation={"vertical"} />

                        <HStack>
                            <AiOutlineEye size={20} />

                            <Text>
                                350 Views
                            </Text>
                        </HStack>
                    </HStack>

                    <Box width={"90%"}>
                        <Link as={routerLink} to={"/blog/post-3"}>
                            <Text fontSize={"3xl"} mb={3}>
                                Introducing Smart Calenders
                            </Text>
                        </Link>
                        
                        <Text>
                            So I said knackered do one ummm I'm telling up the kyver arse over tit smashing lurgy lost the plot, owt to do with me plastered easy peasy ...
                        </Text>

                        <Button as={routerLink} to={"/blog/post-3"} mt={5} size={"lg"}>
                            Read More
                        </Button>
                    </Box>
                </Box>
            </Box>
        </>
    )
}
