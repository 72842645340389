import GrabaLogo from '../../libs/assets/brand/logo.png';
import ObaaioLogo from '../../libs/assets/icons/obaaio_logo.png';
import bgWelcome from '../../libs/assets/images/bg-welcome-to-grabba.png';
import bgIllustrationFour from '../../libs/assets/images/bg-illustration-2.png';

const Brand = {
    logo: GrabaLogo,
    alt: "Grabba Logo"
}

const BrandObaaio = {
    logo: ObaaioLogo,
    alt: "Obaaio Logo"
}


export {
    Brand,
    BrandObaaio,
    bgWelcome,
    bgIllustrationFour
}