import React, { useState } from 'react';
import {
    Redirect,
    Link as routerLink
} from 'react-router-dom';
import {
    Box,
    Link,
    Text,
    Image,
    HStack,
    Button,
    Input,
    VStack,
    Spinner,
    Stack
} from "@chakra-ui/react";

import {
    AttemptSignIn,
    mapStateToProps,
    mapDispatchToProps,
} from '../../../Helpers';
import { connect } from 'react-redux';


function EmailSignIn(props) {
    const {
        auth,
        login,
        logout,
        setSessionToken
    } = props

    const [LogoutInProgress, setLogoutInProgress] = useState(false)
    const [LoginInProgress, setLoginInProgress] = useState(false)

    const [redirect, setRedirect] = useState("")
    const [Email, setEmail] = useState("")
    const [Password, setPassword] = useState("")

    if (redirect) {
        return <Redirect to={redirect} />
    }

    return (
        <VStack h="100%" spacing={"auto"} align="flex-start">

            <Box>
                <Text fontSize={20} fontWeight={"extrabold"}>
                    Sign In.
                </Text>
                <Text fontSize={10}>
                    Capture your favorite sites and save captures to cloud
                </Text>
            </Box>

            <Stack spacing={5} w={"100%"}>
                <Stack spacing={2}>
                    <Text>Email Address:</Text>
                    <Input
                        p={5}
                        size="sm"
                        boxShadow="md"
                        variant="filled"
                        colorScheme="brand"
                        focusBorderColor="brand.500"
                        errorBorderColor="brand.600"
                        placeholder="Enter your email address"
                        value={Email}
                        onChange={e => setEmail(e.target.value)}
                    />
                </Stack>

                <Stack spacing={2}>
                    <Text>Password:</Text>
                    <Input
                        p={5}
                        size="sm"
                        boxShadow="md"
                        variant="filled"
                        colorScheme="brand"
                        focusBorderColor="brand.500"
                        errorBorderColor="brand.600"
                        placeholder="Enter a password"
                        value={Password}
                        onChange={e => setPassword(e.target.value)}
                    />
                </Stack>

                {
                    auth.loggedIn ? (
                        <HStack justify={"right"}>
                            {
                                LogoutInProgress ? (
                                    <Spinner color='red' />
                                ) : null
                            }

                            <Box align={"right"}>
                                <Button onClick={() => { }}
                                    variant="solid"
                                    focusBorderColor="gray"
                                    borderRadius="full"
                                    colorScheme="brand"
                                    bg="gray"
                                    size={"sm"}
                                    color={"white"}
                                    onClick={async () => {
                                        setLogoutInProgress(true)
                                        await logout()
                                        setLogoutInProgress(false)
                                    }}
                                >
                                    You're already logged in. Sign out?
                                </Button>
                            </Box>
                        </HStack>
                    ) : (
                        <HStack justify={"right"}>
                            {
                                LoginInProgress ? (
                                    <Spinner color='red' />
                                ) : null
                            }

                            <Box align={"right"}>
                                <Button onClick={() => { }}
                                    // leftIcon={<Icon as={RiCameraLensFill} />}
                                    variant="solid"
                                    focusBorderColor="red"
                                    borderRadius="full"
                                    colorScheme="brand"
                                    bg={"red"}
                                    size={"sm"}
                                    color={"white"}
                                    onClick={async () => {
                                        setLoginInProgress(true)
                                        await AttemptSignIn({
                                            setRedirect,
                                            sessionToken: auth.sessionToken,
                                            setSessionToken: () => setSessionToken(),
                                            login,
                                            email: Email,
                                            password: Password
                                        })
                                        setLoginInProgress(false)
                                    }}
                                >
                                    Sign In
                                </Button>
                            </Box>
                        </HStack>
                    )
                }

                {/* <Box align={"right"}>
                    <Button onClick={() => { }}
                        // leftIcon={<Icon as={RiCameraLensFill} />}
                        variant="solid"
                        focusBorderColor="red"
                        borderRadius="full"
                        colorScheme="brand"
                        bg="red"
                        color={"white"}
                        onClick={async () => {
                            setLoginInProgress(true)
                            await AttemptSignUp({
                                setRedirect,
                                sessionToken: auth.sessionToken,
                                setSessionToken: () => setSessionToken(),
                                login,
                                fullName: FullName,
                                email: Email,
                                password: Password
                            })
                            setLoginInProgress(false)
                        }}
                    >
                        Sign In
                    </Button>
                </Box> */}
            </Stack>

            <Box>
                <Text fontSize={10}>
                    To sign in with other methods, <Link color={"red"} as={routerLink} to="/auth/login/signin-options">Click here.</Link>
                </Text>
                
                <Text fontSize={10}>
                    Don't have an account? <Link color={"red"} as={routerLink} to="/auth/register">Click here</Link> to sign up.
                </Text>
            </Box>
            

            <Box>
                <Text fontSize={10}>
                    By registering, you agree with our terms and conditions and privacy policy.
                </Text>
            </Box>
        </VStack>
    )
}

export default connect(mapStateToProps, mapDispatchToProps) (EmailSignIn)