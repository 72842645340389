import GrabaLogo from '../../libs/assets/brand/logo.png'

const Brand = {
    logo: GrabaLogo,
    alt: "Grabba Logo",
    color: "#FE805A"
}

export {
    Brand
}