import React, { useState, useEffect } from 'react';
import {
    Redirect,
    Link as routerLink
} from 'react-router-dom';
import {
    Box,
    Link,
    Text,
    Image,
    HStack,
    Button,
    Input,
    Spinner,
    Divider,
    VStack,
    Stack
} from "@chakra-ui/react";

import {
    AttemptSignUp,
    mapStateToProps,
    mapDispatchToProps,
} from '../../Helpers';

import { Brand, bgIllustrationFour } from '../../Assets';
import { connect } from 'react-redux';


function Index(props) {
    const {
        auth,
        login,
        logout,
        setSessionToken
    } = props

    const [LogoutInProgress, setLogoutInProgress] = useState(false)
    const [RegisterationInProgress, setRegisterationInProgress] = useState(false)

    const [redirect, setRedirect] = useState("")
    const [FullName, setFullName] = useState("")
    const [Email, setEmail] = useState("")
    const [Password, setPassword] = useState("")

    if (redirect) {
        return <Redirect to={redirect} />
    }

    return (
        <Box bg={"white"} px={20} py={20} boxShadow={"lg"} borderRadius={"2xl"} zIndex={2}>
            <HStack spacing={10}>
                <Box>
                    <Link as={routerLink} to={"/"}>
                        <Image src={Brand.logo} alt={Brand.alt} h={65} mb={10} />
                    </Link>

                    <Image src={bgIllustrationFour} objectFit="contain" h="300" />
                </Box>

                <Divider orientation='vertical' />

                <VStack h="100%" spacing={"auto"} align="flex-start">
                    <Box mb={5}>
                        <Text fontSize={20} fontWeight={"extrabold"}>
                            Create Account.
                        </Text>
                        <Text fontSize={10}>
                            Capture your favorite sites and save captures to cloud
                        </Text>
                    </Box>

                    <Stack spacing={5} w={"100%"}>
                        <Stack spacing={2}>
                            <Text>Full Name:</Text>
                            <Input
                                p={5}
                                size="sm"
                                boxShadow="md"
                                variant="filled"
                                colorScheme="brand"
                                focusBorderColor="brand.500"
                                errorBorderColor="brand.600"
                                placeholder="Enter your full name"
                                value={FullName}
                                onChange={e => setFullName(e.target.value)}
                            />
                        </Stack>

                        <Stack spacing={2}>
                            <Text>Email Address:</Text>
                            <Input
                                p={5}
                                size="sm"
                                boxShadow="md"
                                variant="filled"
                                colorScheme="brand"
                                focusBorderColor="brand.500"
                                errorBorderColor="brand.600"
                                placeholder="Enter your email address"
                                value={Email}
                                onChange={e => setEmail(e.target.value)}
                            />
                        </Stack>

                        <Stack spacing={2}>
                            <Text>Password:</Text>
                            <Input
                                p={5}
                                size="sm"
                                boxShadow="md"
                                variant="filled"
                                colorScheme="brand"
                                focusBorderColor="brand.500"
                                errorBorderColor="brand.600"
                                placeholder="Enter a password"
                                value={Password}
                                onChange={e => setPassword(e.target.value)}
                            />
                        </Stack>

                        {
                            auth.loggedIn ? (
                                <HStack justify={"right"}>
                                    {
                                        LogoutInProgress ? (
                                            <Spinner color='red' />
                                        ) : null
                                    }

                                    <Box align={"right"}>
                                        <Button onClick={() => { }}
                                            variant="solid"
                                            focusBorderColor="gray"
                                            borderRadius="full"
                                            colorScheme="brand"
                                            bg="gray"
                                            size={"sm"}
                                            color={"white"}
                                            onClick={async () => {
                                                setLogoutInProgress(true)
                                                await logout()
                                                setLogoutInProgress(false)
                                            }}
                                        >
                                            You're already logged in. Sign out?
                                        </Button>
                                    </Box>
                                </HStack>
                            ) : (
                                <HStack justify={"right"}>
                                    {
                                        RegisterationInProgress ? (
                                            <Spinner color='red' />
                                        ) : null
                                    }

                                    <Box align={"right"}>
                                        <Button onClick={() => { }}
                                            // leftIcon={<Icon as={RiCameraLensFill} />}
                                            variant="solid"
                                            focusBorderColor="red"
                                            borderRadius="full"
                                            colorScheme="brand"
                                            bg={"red"}
                                            size={"sm"}
                                            color={"white"}
                                            onClick={async () => {
                                                setRegisterationInProgress(true)
                                                await AttemptSignUp({
                                                    setRedirect,
                                                    sessionToken: auth.sessionToken,
                                                    setSessionToken: () => setSessionToken(),
                                                    login,
                                                    fullName: FullName,
                                                    email: Email,
                                                    password: Password
                                                })
                                                setRegisterationInProgress(false)
                                            }}
                                        >
                                            Sign Up
                                        </Button>
                                    </Box>
                                </HStack>
                            )
                        }
                        
                    </Stack>

                    <Box>
                        <Text fontSize={10} mt={5}>
                            Already have an account? <Link color={"red"} as={routerLink} to="/auth/login/signin-options">Sign In</Link>.
                        </Text>

                        <Text fontSize={10}>
                            By registering, you agree with our terms and conditions and privacy policy.
                        </Text>
                    </Box>
                </VStack>
            </HStack>
        </Box>
    )
}

export default connect(mapStateToProps, mapDispatchToProps) (Index)