import { bindActionCreators } from 'redux';
import { setSessionToken, login, logout } from '../../libs/redux/actions/AuthActions';

// import * as  axios from 'axios';


export const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            login,
            logout,
            setSessionToken,
        },
        dispatch
    )
}

export const mapStateToProps = (state) => {
    const { auth } = state
    return {
        auth,
    }
}

export const AttemptSignIn = async ({
    setRedirect,
    sessionToken,
    // setSessionToken,
    login,
    email,
    password
}) => {
    const payload = {
        email: email,
        password: password,
    }

    console.log("payload: ", payload)

    // await axios.post(apiUrl + `/auth/login`,
    //     payload,
    //     {
    //         headers: { ruid: sessionToken },
    //     }
    // )
    //     .then(async function (response) {
    //         const ruid = response.headers.ruid
    //         console.log("ruid: ", ruid)

    //         const responseData = response.data
    //         console.log("responseData: ", responseData)

    //         await login(responseData)
    //         // setSessionToken("")
    //         return setRedirect("/")
    //     })
    //     .catch(function (error) {
    //         console.log(error);
    //     });
}

export const AttemptSignUp = async ({
    setRedirect,
    sessionToken,
    // setSessionToken,
    login,
    fullName,
    email,
    password
}) => {
    const firstName = String(fullName).split(" ")[0]
    const lastName = String(fullName).split(" ")[1]

    const payload = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password,
    }

    console.log("payload: ", payload)

    // await axios.post(apiUrl + `/auth/register`,
    //     payload,
    //     {
    //         headers: { ruid: sessionToken },
    //     }
    // )
    //     .then(async function (response) {
    //         const ruid = response.headers.ruid
    //         console.log("ruid: ", ruid)

    //         const responseData = response.data
    //         console.log("responseData: ", responseData)

    //         await login(responseData)
    //         // setSessionToken("")
    //         return setRedirect("/")
    //     })
    //     .catch(function (error) {
    //         console.log(error);
    //     });
}
