import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    withRouter
} from "react-router-dom";


import AuthScreens from './Auth/Index';
import BlogScreens from './Blog/Index';
import HomeScreen from './Home/Index';
import OnBoarding from './OnBoarding/Index';


function RouterBase() {

    return (
        <>
            <Router>


                {/* A <Switch> looks through its children <Route>s and
                renders the first one that matches the current URL. */}

                <Switch>
                    <Route path="/auth">
                        <AuthScreens />
                    </Route>

                    <Route path="/blog">
                        <BlogScreens />
                    </Route>

                    <Route path="/get-started">
                        <OnBoarding />
                    </Route>

                    <Route path="/">
                        <HomeScreen />
                    </Route>

                </Switch>

            </Router>
        </>
    );
}

export default withRouter(RouterBase)