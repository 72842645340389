import { extendTheme } from "@chakra-ui/react"

// 2. Extend the theme to include custom colors, fonts, etc
const colors = {
    // brand: "#25295A",
    brand: "#FE805A",
    secondary: "#1d1e6d",
    
    // brand: "#FC1A29",
    // gray: {
    //     900: "#4a3c3c",
    //     800: "#7e6667",
    //     700: "#9e8283",
    //     600: "#b19b9b",
    //     500: "#c1afaf",
    //     400: "#cfc1c1",
    //     300: "#dbd1d1",
    //     200: "#e6dfdf",
    //     100: "#f1eced",
    //     50: "#faf9f9",
    // },
    // brand: {
    //     900: "#810d15",
    //     800: "#d61623",
    //     700: "#fc404d",
    //     600: "#fd747e",
    //     500: "#fe959c",
    //     400: "#feafb4",
    //     300: "#fec4c8",
    //     200: "#fed7da",
    //     100: "#ffe8ea",
    //     50: "#fff8f8",
    // },
}

export const theme = extendTheme({
    colors,
    components: {
        Button: {
            baseStyle: {
                _focus: {
                    boxShadow: 'none'
            }   } 
        },
        Modal: {
            baseStyle: {
                _focus: {
                    boxShadow: 'none'
                }
            }
        }
    }
})
