import React from 'react';
import {
    Link as routerLink
} from 'react-router-dom';
import {
    Box,
    Text,
    Link,
    Image,
    Stack,
    HStack,
    VStack,
    Divider,
    Container
} from "@chakra-ui/react";

import {
    Brand
} from '../Assets';

import { Icon } from "@chakra-ui/icon";
import { FaTwitter, FaLinkedin, FaEnvelope } from "react-icons/fa";


export default function Index(props) {
    const {
        auth,
        altLogo = false,
        showMenu = true,
    } = props

    return (
        <Box bg="secondary" color="white" w="100%" p={4}>
            <Container maxW="8xl">
                {
                    showMenu ? (
                        <>
                            <Stack direction={['column', 'column', 'row']} alignItems={"center"} justifyContent={"space-between"} py={["2", "5", "5"]}>
                                <Link as={routerLink} to={"/"} px="0">
                                    <Image src={altLogo ? Brand.logoAlt : Brand.logo} alt={Brand.alt} h={["16", "16", "20", "20", "20"]} />
                                </Link>

                                <Stack spacing={[5, 5, 10, 25]} w={["100%", "100%", "60%"]} direction={['column', 'column', 'row']} alignItems="center" justify={"end"}>
                                    <Text fontSize={18} fontWeight={"extrabold"} my={[0, 0, 0, 5]}>
                                        Terms & Condition
                                    </Text>

                                    <Text fontSize={18} fontWeight={"extrabold"} my={[0, 0, 0, 5]}>
                                        Privacy Policy
                                    </Text>
                                </Stack>
                            </Stack>

                            <Divider orientation='horizontal' my={5} />
                        </>
                    ) : null
                }
                

                <Stack direction={['column', 'column', 'row']} justifyContent="space-between" alignItems={"center"} mb={5}>
                    <Text my={"5"}>
                        @ 2021 all rights reserved
                    </Text>

                    <HStack spacing={5} my={"5"}>
                        <Icon as={FaTwitter} boxSize={7} />
                        <Icon as={FaEnvelope} boxSize={7} />
                        <Icon as={FaLinkedin} boxSize={7} />
                    </HStack>
                </Stack>

            </Container>
        </Box>
    )
}
