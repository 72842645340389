import { bindActionCreators } from 'redux';
import { setSessionToken } from '../../libs/redux/actions/AuthActions';

import * as  axios from 'axios';

import { saveAs } from 'file-saver';


export const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            setSessionToken,
        },
        dispatch
    )
}

export const mapStateToProps = (state) => {
    const { auth } = state
    return {
        auth,
    }
}

export const AttemptDownloadCapture = async ({
    auth,
    setSessionToken,
    fileName,
    filePath,
}) => {    
    // await axios.get(apiUrl + `${filePath}`, {
    //     headers: {
    //         ruid: auth.sessionToken,
    //         Authorization: `Bearer ${auth.accessToken}`
    //     },
    //     responseType: 'blob'
    // })
    //     .then(function (response) {
    //         const ruid = response.headers.ruid
    //         if (ruid && ruid != auth.sessionToken) {
    //             setSessionToken(ruid)
    //         }
    //         const blob =  URL.createObjectURL(response.data)
    //         return saveAs(blob, fileName);

    //     })
    //     .catch(function (error) {
    //         console.log(error);
    //     });
}

export const AttemptCaptureWebsite = async ({
    auth,
    toast,
    onOpen,
    captureUrl,
    captureType,
    captureSize,
    setSessionToken,
    setCaptureBlob,
    captureFullScreen
}) => {
    // Set Capture URL protocol
    if (
        captureUrl.startsWith("http://")
        ||
        captureUrl.startsWith("https://")
    ) {} else {
        captureUrl = "https://" + captureUrl
    }
    // Validate Capture URL
    try {
        new URL(captureUrl);
    } catch (_) {
        return toast({
            title: 'Invalid URL',
            description: "The URL entered is not a valid URL",
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top-left',
        })
    }

    // await axios.post(apiUrl + '/capture/', {
    //     captureUrl: captureUrl,
    //     captureSize: captureSize,
    //     captureType: captureType,
    //     captureFullScreen: captureFullScreen
    // }, {
    //     headers: {
    //         ruid: auth.sessionToken,
    //         Authorization: `Bearer ${auth.accessToken}`
    //     }
    // })
    //     .then(async function (response) {
    //         const ruid = response.headers.ruid
    //         if (ruid && ruid != auth.sessionToken) {
    //             setSessionToken(ruid)
    //         }
    //         await setCaptureBlob(response.data)
    //         // Open Modal
    //         onOpen()
    //     })
    //     .catch(function (error) {
    //         console.log(error);
    //     });
}

