import React from "react";
import {
    Switch,
    Route,
    withRouter,
    useRouteMatch
} from "react-router-dom";

import BlogList from "./components/BlogList";
import BlogDetail from "./components/BlogDetail";


function RouterBase() {
    const match = useRouteMatch()

    return (
        <>
            <Switch>
                <Route exact path={`${match.path}/:blogPostSlug`}>
                    <BlogDetail />
                </Route>

                <Route exact path={`${match.path}`}>
                    <BlogList />
                </Route>
            </Switch>
        </>
    );
}

export default withRouter(RouterBase)