import { bindActionCreators } from 'redux';


export const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            
        },
        dispatch
    )
}

export const mapStateToProps = (state) => {
    const { auth } = state
    return {
        auth,
    }
}
