import React, { useState, useEffect } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    withRouter,
    Redirect,
    Link as routerLink,
    useRouteMatch
} from "react-router-dom";
import {
    Box,
    Text,
    Image,
    HStack,
    Center,
    Divider,
    Container
} from "@chakra-ui/react";

import { PostMan } from '../../../Helpers';
import { blogUrl, blogHeaders, blogId } from '../../../App.json';

import BlogHeader from "./BlogHeader";
import BlogListItem from "./BlogListItem";
import SidePanel from "./SidePanel";
import Footer from '../../../components/Footer/Index';
import PageActivityIndicator from "../../../components/Indicator/PageActivityIndicator";


function BlogList() {
    const [Blog, setBlog] = useState(null)

    const AttemptFetchBlog = async () => {
        const responseObject = await PostMan(`/blog/${blogId}/posts`, 'get', null, true, blogUrl, blogHeaders)

        if (responseObject.status === 'success') {
            let Blog = responseObject.data
            console.log("Blog: ", Blog)
            // Update FormFields
            return setBlog(Blog)

        } else {
            // Set New State
            console.log("Error: ", Error)
            console.log("responseObject: ", responseObject)
        }
    }

    useEffect(() => {
        // Fetch Blog
        AttemptFetchBlog()
    
    }, [])

    return (
        <>
            {
                Blog ? (
                    <>
                        <BlogHeader 
                            title={Blog.name}
                            crumbs={[
                                {
                                    title: "BLOG",
                                    path: null
                                },
                            ]}
                        />

                        <Container maxW={"6xl"} py={100}>
                            <HStack alignItems={"flex-start"} spacing={5}>
                                <Box width={"70%"}>
                                    <BlogListItem />
                                </Box>

                                <Box width={"30%"} height={1200}>
                                    <SidePanel />
                                </Box>
                            </HStack>
                        </Container>

                        <Footer />
                    </>
                ) : (
                    <PageActivityIndicator />
                )
            }
        </>
    );
}

export default withRouter(BlogList)