import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    withRouter,
    Redirect,
    useRouteMatch
} from "react-router-dom";
import {
    Box,
    Image
} from "@chakra-ui/react";

import LoginScreen from './components/LoginScreen/Index';
import RegisterScreen from './components/RegisterScreen/Index';

import bgShapes from '../../libs/assets/images/bgShapes.png'

function RouterBase() {
    const match = useRouteMatch()

    return (
        <>
            <Box position="absolute" top={0} bottom={0} left={0} right={0}>
                <Image src={bgShapes} w={"100%"} h={"100%"} objectFit={"cover"} zIndex={1} />
            </Box>

            <Box display="flex" alignItems="center" justifyContent="center"
                w={"100%"} h={"100vh"}
            >
                <Switch>
                    <Route exact path={`${match.path}/register`}>
                        <RegisterScreen />
                    </Route>

                    <Route path={`${match.path}/login`}>
                        <LoginScreen />
                    </Route>

                    <Route exact path={`${match.path}`}>
                        <Redirect to="/auth/login" />
                    </Route>
                </Switch>
            </Box>
        </>
    );
}

export default withRouter(RouterBase)