import React, { useEffect } from 'react';
import { Box, Button, Container, HStack, Input, InputGroup, InputRightElement, Image, Stack, Text, useToast } from '@chakra-ui/react';

import { useLocation } from 'react-router-dom';
import Footer from '../../components/Footer/Index';
import Header from '../../components/Header/Index';

import { CircleLoader } from 'react-spinners';
import { 
    FaFacebook,
    FaGoogle,
    FaTwitter,
    FaArrowAltCircleLeft,
    FaArrowAltCircleRight 
} from "react-icons/fa";

import { Brand } from '../../components/Assets';
import { PostMan, ValidateEmail } from '../../Helpers';


export default function Index() {
    const toast = useToast()

    const {
        search: locationParams,
    } = useLocation()
    const emailFromUrl = new URLSearchParams(locationParams).get("email")

    const [isLoading, setIsLoading] = React.useState(true)
    const [isSigningUp, setIsSigningUp] = React.useState(false)

    const [ActiveStack, setActiveStack] = React.useState('')
    
    const [Email, setEmail] = React.useState('')
    const [Password, setPassword] = React.useState('')
    
    const [ShowPassword, setShowPassword] = React.useState(false)

    const AttemptSignUp = async () => {
        // Start Loader
        setIsSigningUp(true)
        // Payload
        let payload = {
            email: Email,
            password: Password,
        }
        // Attempt Submit
        const responseObject = await PostMan("/auth/register/", 'post', payload)
        // Stop Loader
        setIsSigningUp(false)
        //
        if (responseObject.status === 'success') {
            let userData = responseObject.data
            console.log("userData: ", userData)
            // Go To Dashboard
            window.location = process.env.REACT_APP_DASHBOARD_URL
        } else
            // On Bad Request (Payload Errors)
            if (responseObject.status === 'bad_request') {
                let fieldErrors = responseObject.data
                for (let field in fieldErrors) {
                    let validationMessage = fieldErrors[field]
                    toast({
                        title: `${field} Field Error`,
                        description: validationMessage,
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                        position: "top-right",
                    })
                }
        } else {
            // Set New State
            console.log("Error: ", Error)
            console.log("responseObject: ", responseObject)
        }
    }

    useEffect(() => {
        if (emailFromUrl) {
            setEmail(emailFromUrl)
            setActiveStack("email")
        }

    }, [])

    return (
        <Stack height={"100vh"} bg={"aliceblue"}>
            <Header altLogo={true} showMenu={false} />

            {/* <Box height={"100%"} bg={`url(${bgIllustrationFour})`} backgroundPosition={["right", "right", "right", "right"]} backgroundRepeat={"no-repeat"} backgroundSize={"contain"}> */}
            <Box height={"100%"}>
                <Container maxW="8xl">
                    {
                        ActiveStack === "email" ? (
                            <Stack direction={"column"} alignItems={"flex-start"} mt={[50, 50, 100, 100]} px={[null, null, 10]} spacing={[5, 10, 10, 10]}>
                                <Box>
                                    <Text color="secondary" textTransform={"uppercase"} fontSize={["2xl", "3xl", "3xl", "5xl"]} fontWeight="extrabold">
                                        Welcome To Sellsar
                                    </Text>

                                    <Text color="grey" fontSize={["xs", "sm", "md", "md"]} mt={0}>
                                        Please enter a valid email address. A verification email would be sent shortly after.
                                    </Text>
                                </Box>

                                <Box>
                                    <Input
                                        mb={"5"}
                                        size={"lg"}
                                        boxShadow={"md"}
                                        variant={"filled"}
                                        borderRadius={"md"}
                                        width={['90%', '90%', '400px', '400px']}
                                        colorScheme={"orange"}
                                        fontSize={["sm", "md"]}
                                        focusBorderColor="brand"
                                        errorBorderColor="brand"
                                        placeholder="Enter your email address..."
                                        value={Email}
                                        type={'email'}
                                        name={'email'}
                                        onChange={e => setEmail(e.target.value)}
                                    />

                                    <Button onClick={async () => {
                                        let title, description
                                        if (Email) {
                                            if (ValidateEmail(Email)) {
                                                return setActiveStack("password")
                                            } else {
                                                title = "Invalid EMAIL"
                                                description = "The EMAIL entered is not a valid EMAIL"
                                            }
                                        } else {
                                            title = "Invalid EMAIL"
                                            description = "The EMAIL field can not be empty"
                                        }
                                        return toast({
                                            title: title,
                                            description: description,
                                            status: "error",
                                            duration: 5000,
                                            isClosable: true,
                                            position: "top-right",
                                        })
                                    }}
                                        size={"lg"}
                                        variant="solid"
                                        fontSize={["md", "lg"]}
                                        focusBorderColor="red"
                                        borderRadius="md"
                                        colorScheme="brand"
                                        bg="brand"
                                        _hover={{ bg: "secondary" }}
                                        rightIcon={<FaArrowAltCircleRight />}
                                    >
                                        Next
                                    </Button>
                                </Box>

                                <Box>
                                    <Text color={"grey"}>
                                        Or, Sign in with:
                                    </Text>

                                    <HStack spacing={"5"} mt={["2", "2", "5", "5"]}>
                                        <Box padding={["3", "3", "5", "5"]} border={"1px solid lightgrey"} borderRadius={"xl"} _hover={{ color: 'brand', border: `1px solid ${Brand.color}` }}>
                                            <FaFacebook />
                                        </Box>

                                        <Box padding={["3", "3", "5", "5"]} border={"1px solid lightgrey"} borderRadius={"xl"} _hover={{ color: 'brand', border: `1px solid ${Brand.color}` }}>
                                            <FaGoogle />
                                        </Box>

                                        <Box padding={["3", "3", "5", "5"]} border={"1px solid lightgrey"} borderRadius={"xl"} _hover={{ color: 'brand', border: `1px solid ${Brand.color}` }}>
                                            <FaTwitter />
                                        </Box>
                                    </HStack>
                                </Box>
                            </Stack>
                        ) : (
                            <>
                                {
                                    ActiveStack === "password" ? (
                                        <Stack direction={"column"} alignItems={"flex-start"} mt={[50, 50, 100, 100]} px={[null, null, 10]} spacing={[5, 10, 10, 10]}>
                                            <Box>
                                                <Text color="secondary" textTransform={"uppercase"} fontSize={["2xl", "3xl", "3xl", "5xl"]} fontWeight="extrabold">
                                                    Welcome To Sellsar
                                                </Text>

                                                <Text color="grey" fontSize={["xs", "sm", "md", "md"]} mt={0}>
                                                    Please enter a secure password.
                                                </Text>
                                            </Box>

                                            <Box>
                                                <InputGroup size='md'>
                                                    <Input
                                                        mb={"5"}
                                                        size={"lg"}
                                                        boxShadow={"md"}
                                                        variant={"filled"}
                                                        borderRadius={"md"}
                                                        width={['100%', '100%', '400px', '400px']}
                                                        colorScheme={"orange"}
                                                        fontSize={["sm", "md"]}
                                                        focusBorderColor="brand"
                                                        errorBorderColor="brand"
                                                        placeholder="Enter your password"
                                                        value={Password}
                                                        type={ShowPassword ? 'text' : 'password'}
                                                        onChange={e => setPassword(e.target.value)}
                                                        required={true}
                                                    />
                                                    <InputRightElement width={"14"} top={"5px"} right={"5px"}>
                                                        <Button size='sm' bg={"secondary"} color={"white"} _hover={{ color: "brand" }}
                                                            onClick={() => setShowPassword(!ShowPassword)}
                                                        >
                                                            {ShowPassword ? 'Hide' : 'Show'}
                                                        </Button>
                                                    </InputRightElement>
                                                </InputGroup>

                                                <Button onClick={async () => setActiveStack("email")}
                                                    mr={2}
                                                    size={"lg"}
                                                    variant="solid"
                                                    fontSize={["md", "lg"]}
                                                    focusBorderColor="red"
                                                    borderRadius="md"
                                                    colorScheme="brand"
                                                    bg="brand"
                                                    _hover={{ bg: "secondary" }}
                                                    leftIcon={<FaArrowAltCircleLeft />}
                                                >
                                                    Back
                                                </Button>

                                                <Button onClick={async () => {
                                                    let title, description
                                                    if (Password) {
                                                        if (String(Password).length > 6) {
                                                            return AttemptSignUp()
                                                        } else {
                                                            title = "Invalid PASSWORD"
                                                            description = "The PASSWORD should be at least six (6) characters long"
                                                        }
                                                    } else {
                                                        title = "Invalid PASSWORD"
                                                        description = "The PASSWORD field can not be empty"
                                                    }
                                                    return toast({
                                                        title: title,
                                                        description: description,
                                                        status: "error",
                                                        duration: 5000,
                                                        isClosable: true,
                                                        position: "top-right",
                                                    })
                                                }}
                                                    size={"lg"}
                                                    variant="solid"
                                                    fontSize={["md", "lg"]}
                                                    focusBorderColor="red"
                                                    borderRadius="md"
                                                    colorScheme="brand"
                                                    bg={isSigningUp ? "secondary" : "brand"}
                                                    _hover={{ bg: "secondary" }}
                                                    rightIcon={isSigningUp ? <CircleLoader size={20} color={'white'} /> : <FaArrowAltCircleRight />}
                                                >
                                                    Continue
                                                </Button>
                                            </Box>
                                        </Stack>
                                    ) : (
                                        <Stack direction={"column"} alignItems={"flex-start"} mt={[50, 50, 100, 100]} px={[null, null, 10]} spacing={10}>
                                            <Box>
                                                <Text color="secondary" textTransform={"uppercase"} fontSize={["2xl", "3xl", "3xl", "5xl"]} fontWeight="extrabold">
                                                    Welcome To Sellsar
                                                </Text>

                                                <Text color="grey" fontSize={["xs", "sm", "md", "md"]} mt={0}>
                                                    We are on our way. This would only take a few minutes...
                                                </Text>
                                            </Box>

                                            <Button onClick={async () => setActiveStack("email")}
                                                size={"lg"}
                                                variant="solid"
                                                fontSize={["md", "lg"]}
                                                focusBorderColor="red"
                                                borderRadius="md"
                                                colorScheme="brand"
                                                bg="brand"
                                                _hover={{ bg: "secondary" }}
                                                rightIcon={<FaArrowAltCircleRight />}
                                            >
                                                Get Started
                                            </Button>
                                        </Stack>
                                    )
                                }
                            </>



                        )
                    }
                </Container>
            </Box>

            <Footer showMenu={false} />
        </Stack>
    )
}
