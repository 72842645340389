import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    withRouter,
    Redirect,
    Link as routerLink,
    useRouteMatch
} from "react-router-dom";
import {
    Box,
    Text,
    Link,
    HStack,
    Center,
    Divider
} from "@chakra-ui/react";

import { bgBanner } from '../Assets';

import Header from '../../../components/Header/Index';


export default function BlogHeader(props) {
    const {
        title,
        crumbs
    } = props

  return (
      <Box width={"100%"} bg={`url(${bgBanner})`} pb={100}>
          <Header />

          <Center height={250} flexDirection={"column"}>
              <Text color={"brand"} fontSize={"5xl"} fontWeight={"bold"}>{title}</Text>

              <HStack spacing={5} my={5}>
                  <Link as={routerLink} to={"/"}>
                      <Text color={"white"}>HOME</Text>
                  </Link>

                  {
                    crumbs.map(crumb => {
                        console.log("crumb: ", crumb)
                        return (
                            <>
                                <Divider color={"white"} orientation={"vertical"} />

                                <Text color={"white"}>
                                    {crumb.title}
                                </Text>
                            </>
                        )
                    })
                  }
              </HStack>
          </Center>

      </Box>
  )
}
