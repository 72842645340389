import React from 'react';
import {
    Link as routerLink
} from 'react-router-dom';
import {
    Box,
    Icon,
    Link,
    Image,
    HStack,
    Button,
    useBreakpointValue,
    Container,
} from "@chakra-ui/react";

import {
    mapStateToProps,
    mapDispatchToProps
} from './Helpers';

import {
    Brand
} from '../Assets';

import { connect 
} from 'react-redux';

import { FaArrowAltCircleRight } from "react-icons/fa";

function Index(props) {
    const {
        auth,
        altLogo=false,
        showMenu=true,
    } = props

    return (
        <Container maxW="8xl">
            <Box display="flex" alignItems="center" justifyContent="space-between"
                bg="transparent" w="100%" h={100} py={5} px={[null, null, 10]}
            >
                <Link as={routerLink} to={"/"} px="0">
                    <Image src={altLogo ? Brand.logoAlt : Brand.logo} alt={Brand.alt} h={["16", "16", "20", "20", "20"]} />
                </Link>

                {
                    showMenu ? (
                        <HStack display={["none", "none", "flex"]} spacing={10} px={25} textTransform="uppercase">
                            <HStack spacing={5}>
                                <Button as={routerLink} to={"/"} size="sm" colorScheme="blue" borderRadius="md" px={10} variant="link" color={"white"}>
                                    Home
                                </Button>

                                {/* <Button as={routerLink} to={"/#pricing"} size="sm" colorScheme="blue" borderRadius="md" px={10} variant="link" color={"white"}>
                            Pricing
                        </Button> */}

                                <Button as={routerLink} to={"/blog"} size="sm" colorScheme="blue" borderRadius="md" px={10} variant="link" color={"white"}>
                                    Blog
                                </Button>

                                {
                                    auth.loggedIn ? (
                                        <Button as={routerLink} to={"/dashboard"} bg="white" size="sm" px="10" colorScheme="gray" borderRadius="full" variant="solid">
                                            Dashboard
                                        </Button>
                                    ) : (
                                        <>
                                            {/* <Button as={routerLink} to={"/get-started"} size="sm" colorScheme="blue" borderRadius="md" px={10} variant="link" color={"white"}>
                                                Get Started
                                            </Button> */}

                                            {/* <Button as={Link} href={`${dashboardUrl}/login?login_mode=demo`} 
                                                rightIcon={<Icon as={FaArrowAltCircleRight} color={"brand"} />}
                                                size="sm" colorScheme="gray" borderRadius="md" px={5} variant="solid" textColor={"darkblue"}
                                                _hover={{ textDecoration: "none" }}
                                                target={"_blank"}
                                            >
                                                Try Demo
                                            </Button> */}

                                            <Button as={routerLink} to={"/get-started"}
                                                rightIcon={<Icon as={FaArrowAltCircleRight} color={"brand"} />}
                                                size="sm" colorScheme="gray" borderRadius="md" px={5} variant="solid" textColor={"darkblue"}
                                                _hover={{ textDecoration: "none" }}
                                            >
                                                Try Demo
                                            </Button>
                                        </>
                                    )
                                }

                            </HStack>
                        </HStack>
                    ) : null
                }

            </Box>

        </Container>
    )
}

export default connect(mapStateToProps, mapDispatchToProps) (Index)