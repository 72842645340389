import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    withRouter,
    Redirect,
    Link as routerLink,
    useRouteMatch
} from "react-router-dom";
import {
    Box,
    Text,
    Link,
    HStack,
    Image,
    Divider,
    Container
} from "@chakra-ui/react";

import BlogHeader from "./BlogHeader";
import Footer from '../../../components/Footer/Index';

import { AiOutlineComment, AiOutlineEye } from 'react-icons/ai';
import { FaRegCalendarAlt } from 'react-icons/fa';


import { blogPost1, blogPost2, blogPost3, } from '../Assets';


function BlogDetail() {
    // const match = useRouteMatch()

    return (
        <>
            <BlogHeader />

            <Container maxW={"7xl"} py={100}>
                <Image src={blogPost2} />

                <Box>
                    <HStack spacing={5} py={2}>
                        <HStack>
                            <FaRegCalendarAlt size={20} />

                            <Text>
                                29 April 2019
                            </Text>
                        </HStack>

                        <Divider height={8} size={"4xl"} orientation={"vertical"} />

                        <HStack>
                            <AiOutlineComment size={20} />

                            <Text>
                                28 Comments
                            </Text>
                        </HStack>

                        <Divider height={8} size={"4xl"} orientation={"vertical"} />

                        <HStack>
                            <AiOutlineEye size={20} />

                            <Text>
                                350 Views
                            </Text>
                        </HStack>
                    </HStack>
                </Box>

                <Text>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore. 
                    By hiring a consultant, clients have access to deeper levels of expertise than would be financially feasible for them to retain. 
                    Lorem ipsum dolor sit amet, tetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.
                </Text>

                <Text>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore. 
                    By hiring a consultant, clients have access to deeper levels of experd be financially.
                </Text>

                <Text>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore. 
                    By hiring a consultant, clients have access to deeper levels of expertise than would be financially feasible for them to retain. Lorem ipsum dolor sit amet.
                </Text>

                <Divider />
            </Container>

            <Footer />
        </>
    );
}

export default withRouter(BlogDetail)