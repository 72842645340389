import React from 'react';
import {
    Link as routerLink
} from 'react-router-dom';
import {
    Box,
    Text,
    Image,
    HStack,
    VStack,
    Center,
    Link
} from "@chakra-ui/react";

import { Icon } from "@chakra-ui/icon";
import { FaGoogle, FaEnvelope } from "react-icons/fa";

import { Brand, BrandObaaio, bgWelcome } from '../../../Assets';


export default function SignOptions() {
    return (
        <VStack h="100%" spacing={"auto"} align="flex-start">
            <Box>
                <Text fontSize={20} fontWeight={"extrabold"}>
                    Sign In.
                </Text>
                <Text fontSize={10}>
                    Capture your favorite sites and save captures to cloud
                </Text>
            </Box>

            <Box>
                <Text fontSize={10} my={5}>
                    Continue with...
                </Text>
                <HStack>
                    <Link as={routerLink} align={"center"} to={"/"} _hover={{ color: "red" }}>
                        <Center orientation={"vertical"} border={"1px"} borderRadius={"xl"} w={110} h={110}>
                            <Box align={"center"}>
                                <Icon as={FaGoogle} boxSize={7} />
                                <Text fontSize={8} pt={1}>
                                    Google
                                </Text>
                            </Box>
                        </Center>
                    </Link>
                    <Link as={routerLink} align={"center"} to={"/"} _hover={{ color: "red" }}>
                        <Center orientation={"vertical"} border={"1px"} borderRadius={"xl"} w={110} h={110}>
                            <Box align={"center"}>
                                <Image src={BrandObaaio.logo} alt={BrandObaaio.alt} width={70} />

                                <Text fontSize={8} pt={1}>
                                    OBAA.IO
                                </Text>
                            </Box>
                        </Center>
                    </Link>
                    <Link as={routerLink} align={"center"} to={`/auth/login/basic`} _hover={{ color: "red" }}>
                        <Center orientation={"vertical"} border={"1px"} borderRadius={"xl"} w={110} h={110}>
                            <Box align={"center"}>
                                <Icon as={FaEnvelope} boxSize={7} />
                                <Text fontSize={8} pt={1}>
                                    Email Address
                                </Text>
                            </Box>
                        </Center>
                    </Link>
                </HStack>
            </Box>

            <Box>
                <Text fontSize={10}>
                    By registering, you agree with our terms and conditions and privacy policy.
                </Text>
            </Box>
        </VStack>
    )
}
