import React from 'react';
import {
    Link as routerLink,
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    useRouteMatch,
    withRouter
} from "react-router-dom";
import {
    Box,
    Link,
    Text,
    Image,
    HStack,
    Button,
    useBreakpointValue,
    Container,
    Divider,
    VStack,
    Center,
} from "@chakra-ui/react";

import { Brand, BrandObaaio, bgWelcome } from '../../Assets';

import EmailSignIn from './components/EmailSignIn';
import SignOptions from './components/SignInOptions';


function Index() {
    const match = useRouteMatch()

    return (
        <Box bg={"white"} px={20} py={20} boxShadow={"lg"} borderRadius={"2xl"} zIndex={2}>
            <HStack h={400} spacing={10}>
                <Box>
                    <Link as={routerLink} to={"/"}>
                        <Image src={Brand.logo} alt={Brand.alt} h={65} mb={10}/>
                    </Link>

                    <Image src={bgWelcome} objectFit="contain" h="300" />
                </Box>

                <Divider orientation='vertical' />

                <Switch>
                    <Route exact path={`${match.path}/signin-options`}>
                        <SignOptions />
                    </Route>

                    <Route exact path={`${match.path}/basic`}>
                        <EmailSignIn />
                    </Route>

                    <Route exact path={`${match.path}`}>
                        <Redirect to={`/auth/login/signin-options`} />
                    </Route>
                </Switch>
            </HStack>
        </Box>
    )
}

export default withRouter(Index)